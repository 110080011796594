$theme-colors: (
  "primary": aquamarine,
);

$spacers: (
  6: (
    6rem,
  ),
  7: (
    7rem,
  ),
  8: (
    8rem,
  ),
  9: (
    9rem,
  ),
  10: (
    10rem,
  ),
  11: (
    11rem,
  ),
  12: (
    12rem,
  ),
);

@import "node_modules/bootstrap/scss/bootstrap";
